@tailwind base;
@tailwind components;
@tailwind utilities;

@import './components/accordion.css';
@import './components/button.css';
@import './components/typography.css';

main p {
  @apply mb-5;
}

img {
  max-width: 100%;
  margin: auto;
}

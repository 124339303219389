@font-face {
  font-family: "Ellipse";
  src: url("../../fonts/EllipseITCTT-Bold.woff2") format("woff2"),
    url("../../fonts/EllipseITCTT-Bold.woff") format("woff");
}

@font-face {
  font-family: "Brown";
  src: url("../../fonts/Brown-Light.woff2") format("woff2"),
    url("../../fonts/Brown-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Brown";
  src: url("../../fonts/Brown-Bold.woff2") format("woff2"),
    url("../../fonts/Brown-Bold.woff") format("woff");
  font-weight: 700;
}

html {
  font-family: "Brown", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3;
  @apply text-black;

  @media screen(md) {
    font-size: 22px;
  }
}

h1 {
  @apply font-heading text-center;
}

.title-container {
  @apply container;

  h2 {
    @apply font-heading text-center;
    display: inline-block;
    margin-right: auto;
    position: relative;
    background: url("../../img/bg-trait.webp") no-repeat;
    background-position: center bottom;
    background-size: 200% 0.6rem;
    padding-bottom: 0.4rem;
    padding-right: 2%;
  }
}

ul {
  list-style: square;
}

p {
  word-break: break-word;
}

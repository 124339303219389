.accordion {
  @apply py-5 text-grey text-left;
  width: 100%;
  outline: none;
  transition: 0.4s;
  position: relative;
  font-weight: 700;

  &.active,
  &:hover {
    @apply text-red;
  }

  &:after {
    content: '+';
    font-weight: 300;
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.active:after {
    content: '–';
    right: 0.1rem;
  }
}

.panel {
  @apply border-b-2 border-grey;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease;

  .content {
    @apply pb-5;
  }
}
